<template>
  <div>
    <Head/>
    <searchModel/>
    <commonProblemAndMenu/>
    <Foot/>
  </div>
</template>

<script>
import Head from "@/components/head/HomePageTop.vue";
import searchModel from './components/searchModel.vue'
import commonProblemAndMenu from './components/commonProblemAndMenu.vue'
import Foot from "@/components/foot/Foot.vue";

export default {
  components: {
    Head,
    searchModel,
    commonProblemAndMenu,
    Foot
  }
}

</script>

<style lang="scss" scoped>

</style>