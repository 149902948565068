<template>
  <div class="centro-de-ayuda-page">
    <div class="headerRow">
      <div class="headerRowTitle">よくある質問について:</div>
      <div class="commonProblemListContainer">
        <div v-for="(item,index) in commonProblemList" :key="index" class="problem"
             @click="$fun.routerToPage(`/answerResultPage?menu=${item.category_id}&problemId=${item.id}`)">
          <div></div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div v-if="list.length>0" class="announcement">
      <div class="announcementHeader">
        <div class="announcementTitle">{{ $fanyi('网站更新情报') }}</div>
        <div class="pagingContainer">
          <div class="pagingItemContainer" @click="pageChange('left')">
            <img alt="" src="../../../../assets/QA/left.png">
            <div>{{ $fanyi('上一页') }}</div>
          </div>
          <div class="pagingItemContainer" style="margin-left:80px" @click="pageChange('right')">
            <div>{{ $fanyi('下一页') }}</div>
            <img alt="" src="../../../../assets/QA/right.png">
          </div>
        </div>
      </div>
      <div>
        <div v-for="(item,index) in list" :key="index"
             :style="list.length==4&&index==list.length-1?'margin-bottom:0':''"
             class="listItemContainer"
             @click="showInformDialogVisibleFun(item)">
          <div class="icon"></div>
          <div class="date">{{ item.release_date }}</div>
          <div class="u-line">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <!--      公告内容-->
    <div class="holdOrderDialogVisibleContainer">
      <el-dialog
          :visible.sync="showInformDialogVisible"
          center
          width="1200px">
        <div class="header">
          <div>
            <div class="headerTitle">{{ $fanyi('网站更新情报') }}</div>
            <div class="borderContainer"></div>
            <div class="con" v-html="readItem.newDescription"></div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="footerColumn">
      <div class="problemMenuListContainer">
        <div v-for="(item,index) in problemMenu" :key="index" class="columnAndCenterAndCenter"
             @click="$fun.routerToPage(`/answerResultPage?menu=${item.id}`)">
          <img :src="item.image" alt="">
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commonProblemList: [],
      problemMenu: [],
      showInformDialogVisible: false,
      list: [],
      readItem: {},
      form: {
        page: 1,
        pageSize: 4
      },
      total: 0
    }
  },
  created() {
    this.$api.articleFirstTenList().then((res) => {
      this.commonProblemList = res.data;
    });
    this.getData();
    this.$api.articleList().then((res) => {
      this.problemMenu = res.data;
    });
  },
  methods: {
    getData() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (japan_user_token == null) {
        return
      }
      this.$api.announcement(this.form).then((res) => {
        res.data.data.forEach((item) => {
          item.newDescription = this.$fun.deepClone(item.description);
          item.description = item.description.replace(/&nbsp;/g, '');
          item.description = item.description.replace(/<[^>]+>/g, '');
        })
        this.list = res.data.data;
        this.total = res.data.total;
      })
    },
    showInformDialogVisibleFun(item) {
      this.readItem = item
      this.showInformDialogVisible = true;
      this.$forceUpdate();
    },
    pageChange(val) {
      if (val == 'left') {
        if (this.form.page == 1) return this.$message.error(this.$fanyi('当前是第一页'))
        this.form.page--
        this.getData()
      } else {
        if (this.total < 4) return this.$message.error(this.$fanyi('没有下一页了'))
        this.form.page++
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.centro-de-ayuda-page {
  background-color: white;
  padding: 40px 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  .headerRow {
    width: 1400px;
    height: 430px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 40px;

    .headerRowTitle {
      color: #333333;
      font-size: 24px;
      margin-bottom: 40px;
    }

    .marginRight74 {
      margin-right: 74px;
    }

    .commonProblemListContainer {
      display: flex;
      flex-wrap: wrap;
    }

    .problem {
      width: 50%;
      display: flex;
      margin-bottom: 37px;

      diV:first-child {
        width: 14px;
        height: 14px;
        border: 2px solid #B4272B;
        border-radius: 50%;
        margin: 4px 9px 0 0;
      }

      div:last-child {
        width: 600px;
        color: #333333;
        font-size: 16px;
        cursor: pointer;
      }

      div:last-child:hover {
        color: #B5262A;
      }
    }
  }

  .holdOrderDialogVisibleContainer {
    /deep/ .el-dialog {
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    /deep/ .el-dialog__body, /deep/ .el-dialog__header {
      padding: 0;
    }

    /deep/ .el-dialog__close {
      font-size: 19px;
      color: #B4272B;
      font-weight: bold;
    }

    .header {
      padding: 30px 24px 20px 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .headerTitle {
        color: #333333;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
      }

      .borderContainer {
        width: 36px;
        height: 4px;
        background: #B4272B;
        margin: 7px auto 26px;
      }
    }

    .holdOrderContainer {
      .icon {
        width: 20px;
        height: 20px;
        background: #B4272B;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        text-align: center;
        line-height: 20px;
        margin-right: 6px;
      }

      .holdOrderHeaderContainer {
        height: 46px;
        padding-left: 26px;
        line-height: 46px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #DFDFDF;

        .holdOrderHeader {
          color: #333;
        }
      }

      .holdOrderText {
        color: #333333;
        font-weight: bold;
        text-align: center;
        margin: 40px 30px;
      }

      .dialog-footer {
        button {
          width: 120px;
          height: 34px;
          border-radius: 8px;
          padding: 0;
          font-size: 14px;
        }

        button:first-child {
          border: 1px solid #B4272B;
          color: #B4272B;
        }
      }
    }
  }

  .announcement {
    width: 1400px;
    background: #FFFFFF;
    max-height: 352px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 40px 37px 0 40px;

    .announcementHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .announcementTitle {
        font-size: 24px;
        color: #333333;
      }

      .pagingContainer {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .pagingItemContainer {
          display: flex;
          align-items: center;
          cursor: pointer;

          div {
            color: #333333;
          }

          img {
            width: 24px;
          }

          div:first-child {
            margin-right: 10px;
          }

          div:last-child {
            margin-left: 10px;
          }
        }
      }
    }

    .listItemContainer {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;

      .icon {
        width: 8px;
        height: 8px;
        background: #B83135;
        border-radius: 50%;
        margin-right: 10px;
      }

      .date {
        color: #333333;
        margin-right: 72px;
      }

      .u-line {
        width: 987px;
        color: #333333;
      }
    }
  }

  .footerColumn {
    width: 1400px;
  }

  .problemMenuListContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .columnAndCenterAndCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 241px;
    background: #F6F6F6;
    border: 1px solid #DFDFDF;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 40px 40px 0;

    div {
      margin-top: 26px;
      color: #333333;
      font-size: 16px;
    }
  }

  .columnAndCenterAndCenter:nth-child(4n) {
    margin-right: 0;
  }
}
</style>